<template>
  <div class="step">
    <div class="step-header">
      <h3>{{ number }}. {{ content.title }}</h3>
      <Button
        v-tooltip="'Add a new instruction'"
        icon="pi pi-plus"
        :disabled="versionViewer"
        class="p-button-rounded p-button-info add-instruction-button"
        @click="addInstruction"
      />
    </div>
    <ManualInstruction
      v-for="(instruction, index) in content?.instructions"
      :key="index"
      :instruction-model="instruction"
      :versionViewer="versionViewer"
      :number="index + 1"
      :path="`${path}/instructions/${index}`"
      :username="username"
      class="manualInstruction"
      @update-child="updateChild"
      @selection-on-child="selectionOnChild"
    />
  </div>
</template>

<script setup>
import Button from "primevue/button";
import { generate } from "random-words";
import ManualInstruction from "./ManualInstruction.vue";

const emit = defineEmits(["insertChild", "updateChild", "selectionOnChild"]);

const props = defineProps({
  content: {
    type: Object,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  number: {
    type: Number,
    required: true,
  },
  username: {
    type: String,
    required: true,
  },
  versionViewer: {
    type: Boolean,
    default: false,
  },
});

function addInstruction() {
  const newInstruction = {
    text: `Instruction ${generateInstructionName()}`,
  };
  const instructionLength = props.content.instructions?.length || 0;
  const pathToInsert = `${props.path}/instructions/${instructionLength}`;
  emit("insertChild", {
    child: newInstruction,
    path: pathToInsert,
  });
}

function generateInstructionName() {
  return `Instruction ${
    generate({ wordsPerString: 4, minLength: 7, exactly: 1 })[0]
  }`;
}

function updateChild({ path, child }) {
  emit("updateChild", { path, child });
}

function selectionOnChild({
  path,
  startSelectionPosition,
  endSelectionPosition,
}) {
  emit("selectionOnChild", {
    path,
    startSelectionPosition,
    endSelectionPosition,
  });
}
</script>

<style scoped>
.step {
  padding: 20px;
}

.step-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.step-header h3 {
  margin: 0;
  margin-right: 10px;
}

.add-instruction-button {
  padding: 0.5rem;
}

.manualInstruction {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
}
</style>
