import { createApp } from 'vue';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import QuillCursors from 'quill-cursors';
import App from './App.vue';
import router from './router';

Quill.register('modules/cursors', QuillCursors);
const app = createApp(App);
app.use(PrimeVue);
app.directive('tooltip', Tooltip);
app.use(ToastService);
app.use(router);

app.mount('#app');
