<template>
  <div class="main-wrapper">
    <Navbar />
    <Toast>
      <template #message="{ message }">
        <div v-html="message.detail"></div>
      </template>
    </Toast>
    <div class="header-content">
      <div class="title-publish-container">
        <div class="title-buttons-container">
          <h1>{{ procedure.number }}: {{ procedure.title }}</h1>
          <Button
            v-tooltip="'Add a new step'"
            icon="pi pi-plus"
            class="p-button-rounded p-button-info add-step-button"
            @click="addStep"
            :disabled="versionViewer"
          />
        </div>
        <Button
          label="Publish"
          class="p-button-success publish-button"
          @click="publishProcedure"
          :disabled="inProcessOfPublishing"
          v-if="!versionViewer"
        />
        <Button
          label="Restore this version"
          class="p-button-rounded p-button-warning restore-button"
          :disabled="inProcessOfPublishing"
          @click="restoreThisVersion"
          v-if="versionViewer"
        />
      </div>
      <div class="version-info">
        <h5>
          Last published on {{ formattedDate }} by {{ procedure.publisher }}
        </h5>
        <Button
          v-tooltip="'View version history'"
          icon="pi pi-clock"
          class="p-button-rounded p-button-info versions-button"
          @click="toggleVersionsSidebar"
        />
      </div>
      <Sidebar
        v-model:visible="versionsSidebarVisible"
        position="right"
        :modal="true"
        :class="'version-sidebar'"
        :style="{ width: '800px' }"
      >
        <ProcedureVersions :procedure-id="procedure.procedureID" />
      </Sidebar>
    </div>
    <div class="content-section">
      <div v-for="(step, index) in procedure.content?.steps" :key="index">
        <StepComponent
          :versionViewer="versionViewer"
          :content="step"
          :number="index + 1"
          :path="`/steps/${index}`"
          :username="DEBUG_USERNAME"
          @update-child="updateChild"
          @insert-child="insertChild"
          @selection-on-child="selectionOnChild"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Sidebar from "primevue/sidebar";
import { generate } from "random-words";
import ProcedureAPI from "@/api/ProcedureAPI";
import Navbar from "@/components/Navbar.vue";
import StepComponent from "@/components/StepComponent.vue";
import ProcedureVersions from "@/components/ProcedureVersions.vue";
import { immutableJSONPatch } from "immutable-json-patch";

const toast = useToast();
const router = useRouter();
const route = useRoute();
const procedure = ref({
  number: "",
  title: "",
  createdAt: "",
  publisher: "",
  authors: [],
});

const versionViewer = ref(false);

const inProcessOfPublishing = ref(false);

const versionsSidebarVisible = ref(false);

const DEBUG_USERNAME = `user_${generate({
  wordsPerString: 1,
  exactly: 1,
  minLength: 6,
})}`;

let removeRouterNavigationListener = null;
let socket = null;

onMounted(async () => {
  fetchModel(route.params);
  removeRouterNavigationListener = router.beforeEach(checkRoute);
});

async function checkRoute(to, from, next) {
  // if procedureID or versionID is different, fetch the model
  if (
    to.params.procedureID !== route.params.procedureID ||
    to.params.versionID !== route.params.versionID
  ) {
    console.debug(
      `🚦 Fetching model for ${route.params.procedureID} and ${to.params.versionID}`
    );
    await fetchModel(to.params);
  }
  next();
}

onBeforeUnmount(() => {
  socket?.disconnect();
  if (removeRouterNavigationListener) {
    removeRouterNavigationListener();
  }
});

async function restoreThisVersion() {
  try {
    inProcessOfPublishing.value = true;
    await ProcedureAPI.restoreProcedureVersion(
      route.params.procedureID,
      route.params.versionID
    );
    // now route to the editor page
    router.push(`/editor/${route.params.procedureID}`);
    versionViewer.value = false;
    toast.add({
      severity: "success",
      summary: "Success",
      detail: `Procedure restored successfully!`,
    });
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: e.message || "An error occurred while restoring the procedure",
    });
  } finally {
    inProcessOfPublishing.value = false;
  }
}

const formattedDate = computed(() =>
  new Date(procedure.value.createdAt).toLocaleString()
);

async function fetchModel(params) {
  socket?.disconnect();
  socket = ProcedureAPI.initializeSocket(
    route.params.procedureID,
    processAction
  );

  if (params.versionID) {
    versionViewer.value = true;
    procedure.value = await ProcedureAPI.fetchProcedureVersion(
      params.procedureID,
      params.versionID
    );
  } else {
    procedure.value = await ProcedureAPI.fetchProcedureDetails(
      params.procedureID
    );
  }
  processInitialProcedure();
}

function toggleVersionsSidebar() {
  versionsSidebarVisible.value = !versionsSidebarVisible.value;
}

function generateStep() {
  return {
    title: `Step ${
      generate({ wordsPerString: 2, minLength: 7, exactly: 1 })[0]
    }`,
    instructions: [],
    steps: [],
  };
}

function addStep() {
  const child = generateStep();
  const stepLength = procedure.value.content?.steps?.length || 0;
  insertChild({ child, path: `/steps/${stepLength}` });
}

function insertChild({ child, path }) {
  const action = {
    patch: {
      op: "add",
      path: path,
      value: child,
    },
    metadata: {
      author: DEBUG_USERNAME,
      clientCreationAt: new Date().toISOString(),
    },
  };
  processAction(action, false);
  ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function selectionOnChild({
  path,
  startSelectionPosition,
  endSelectionPosition,
}) {
  const action = {
    patch: {
      op: "replace",
      path: `${path}/selection`,
      value: {
        startSelectionPosition,
        endSelectionPosition,
        author: DEBUG_USERNAME,
      },
    },
    metadata: {
      author: DEBUG_USERNAME,
      clientCreationAt: new Date().toISOString(),
    },
  };
  processAction(action);
  ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function updateChild({ child, path }) {
  const action = {
    patch: {
      op: "replace",
      path: path,
      value: child,
    },
    metadata: {
      author: DEBUG_USERNAME,
      clientCreationAt: new Date().toISOString(),
    },
  };
  processAction(action);
  ProcedureAPI.sendAction(socket, route.params.procedureID, action);
}

function processInitialProcedure() {
  const patches = procedure.value.loadedActions?.map((action) => action.patch);
  if (!patches) {
    return;
  }
  const newContent = immutableJSONPatch(procedure.value.content, patches);
  procedure.value.content = newContent;
}

function processAction(action, ignoreOwnActions = true) {
  if (ignoreOwnActions && action.metadata.author === DEBUG_USERNAME) {
    return;
  }
  const newContent = immutableJSONPatch(procedure.value.content, [
    action.patch,
  ]);
  procedure.value.content = newContent;
}

async function publishProcedure() {
  try {
    inProcessOfPublishing.value = true;
    const publishedInfo = await ProcedureAPI.publishProcedure(
      route.params.procedureID
    );
    // reload the procdure object
    fetchModel(route.params);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: `Procedure published successfully!<br> <a href="${publishedInfo.linkToProcedure}" target="_blank">View Procedure</a>`,
    });
  } catch (e) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: e.message || "An error occurred while publishing the procedure",
    });
  } finally {
    inProcessOfPublishing.value = false;
  }
}
</script>
<style scoped>
.main-wrapper {
  padding: 20px;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.title-publish-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* provide some space between the title and the button */
}

.add-step-button {
  margin-left: 1rem;
  /* Consider removing since gap is used */
}

.publish-button {
  margin-right: 2rem;
  /* Ensures the button is pushed to the far right */
}

.restore-button {
  margin-right: 2rem;
  /* Ensures the button is pushed to the far right */
}

.version-info {
  display: flex;
  align-items: center;
}

.versions-button {
  margin-left: 1rem;
}
</style>
