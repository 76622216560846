<template>
  <div>
    <DataTable :value="versions">
      <Column field="versionID" header="Version ID">
        <template #body="slotProps">
          <div style="display: flex; flex-direction: column;">
            <span v-if="isCurrentVersion(slotProps.index)" class="current-version-badge">Current</span>
            <router-link :to="`/editor/${route.params.procedureID}/version/${slotProps.data.versionID}`">
              {{ slotProps.data.versionID }}
            </router-link>
          </div>
        </template>
        DataTable
      </Column>
      <Column field="createdAt" header="Created At" />
      <Column field="title" header="Title" />
      <Column field="number" header="Number" />
      <Column field="publisher" header="Publisher" />
    </DataTable>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProcedureAPI from '@/api/ProcedureAPI';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';

const route = useRoute();
const versions = ref([]);

function isCurrentVersion(index) {
  return index === 0;
}

onMounted(async () => {
  versions.value = await ProcedureAPI.fetchProcedureVersions(route.params.procedureID);
});

onBeforeRouteUpdate(async (to) => {
  versions.value = await ProcedureAPI.fetchProcedureVersions(to.params.procedureID);
});


</script>

<style scoped>
.current-version-badge {
  color: #fff;
  background-color: darkgoldenrod;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 6px;
}
</style>