<template>
  <div class="container">
    <h1 class="title">
      PrideX
    </h1>
    <div class="icon-container">
      <img
        :src="lionUrl"
        alt="Lion"
      >
    </div>
    <Button
      label="Login"
      icon="pi pi-sign-in"
      class="p-button-lg p-button-rounded login-button"
      @click="goToDashboard"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Button from 'primevue/button';
import lionUrl from '@/assets/lion.svg';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

const router = useRouter();

function goToDashboard() {
	router.push('/dashboard');
}
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	text-align: center;
}

.icon-container {
	margin: 2rem 0;
}

.login-button {
	font-size: 1.5rem;
}

.title {
	font-family: 'futura', cursive;
	font-size: 10rem;
	margin: 0;
}
</style>
