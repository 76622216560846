<template>
  <div class="instruction">
    <div class="instruction-header">
      <h3>{{ number }}.</h3>
    </div>
    <div ref="editorContainer" class="editorContainer" />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { debounce, isEqual } from "lodash";
import Quill from "quill";

const TEXT_DEBOUNCE_TIME = 200;
const SELECTION_DEBOUNCE_TIME = 200;

const editorContainer = ref(null);

let quill;

const userCursors = {};
let lastEmittedSelection = {};

const props = defineProps({
  instructionModel: { type: Object, required: true },
  path: { type: String, required: true },
  number: { type: Number, required: true },
  username: { type: String, required: true },
  versionViewer: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.instructionModel.text,
  (newText) => {
    // neeed to preserve cursor locations here
    if (quill.getText() === newText) return;
    const currentSelection = quill.getSelection();
    quill.setText(newText, "api");
    if (currentSelection)
      quill.setSelection(
        currentSelection.index,
        currentSelection.length,
        "api"
      );
  }
);

watch(
  () => props.instructionModel.selection,
  (newSelection) => {
    if (newSelection && newSelection?.author !== props.username) {
      // see if we have the cursor yet
      const cursorModule = quill.getModule("cursors");
      if (!userCursors[newSelection.author]) {
        const newCursor = cursorModule.createCursor(
          newSelection.author,
          newSelection.author,
          "purple"
        );
        newCursor.toggleFlag(newSelection.author, true);
        userCursors[newSelection.author] = newCursor;
      }
      if (newSelection.startSelectionPosition === null) {
        cursorModule.removeCursor(newSelection.author);
        delete userCursors[newSelection.author];
      } else {
        cursorModule.moveCursor(newSelection.author, {
          index: newSelection.startSelectionPosition,
          length:
            newSelection.endSelectionPosition -
            newSelection.startSelectionPosition,
        });
      }
    }
  }
);

const emit = defineEmits(["updateChild", "selectionOnChild"]);

function emitInstructionChange(newText) {
  emit("updateChild", {
    path: props.path,
    child: { text: newText },
    username: props.username,
  });
}

function emitSelectionChange(range) {
  let startSelectionPosition = null;
  let endSelectionPosition = null;
  if (range) {
    startSelectionPosition = range.index;
    endSelectionPosition = range.index + range.length;
  }
  const newSelectionEventToEmit = {
    path: props.path,
    startSelectionPosition,
    endSelectionPosition,
    username: props.username,
  };
  // don't fire if the selection hasn't changed
  if (isEqual(lastEmittedSelection, newSelectionEventToEmit)) return;
  emit("selectionOnChild", newSelectionEventToEmit);
  lastEmittedSelection = newSelectionEventToEmit;
}

const textChangedHandler = debounce((delta, oldContents, source) => {
  if (source === "api") return;
  const quillContent = quill.getText();
  emitInstructionChange(quillContent);
}, TEXT_DEBOUNCE_TIME);

const selectionChangeHandler = debounce((range) => {
  emitSelectionChange(range);
}, SELECTION_DEBOUNCE_TIME);

onMounted(async () => {
  quill = new Quill(editorContainer.value, {
    modules: {
      cursors: true,
    },
    theme: "snow",
  });
  if (props.versionViewer) {
    quill.disable();
  } else {
    quill.on("text-change", textChangedHandler);
    quill.on("selection-change", selectionChangeHandler);
  }
  quill.setText(`${props.instructionModel.text}`, "api");
});
</script>

<style scoped>
.instruction {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  height: 100%;
}

.instruction-header {
  margin-bottom: 10px;
  /* Spacing between the header and the editor */
}

.editor-container {
  height: 100%;
}
</style>
